import { getCompanies } from '@/modules/project/model';
import { Company, RightsEnum } from '@/modules/project/types/project.types';
import { Divider, Form, Radio, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterOption } from '../../shared/form/form.utils';
import { ModalProps } from '../modalProps';

export const AuthorizationModal: FC<ModalProps> = ({ type, kisAuthorization, form }) => {
  const { t } = useTranslation();
  const [companies, setCompanys] = useState<Company[]>();

  useEffect(() => {
    const abortController = new AbortController();
    getCompanies(abortController.signal).then(data => {
      setCompanys(data);
    });
    if (type === 'edit' && kisAuthorization && form) {
      form.setFieldsValue(kisAuthorization);
    }
    return () => {
      abortController.abort();
    };
  }, [kisAuthorization, form, type]);

  return (
    <>
      <Divider />
      <Form.Item hidden noStyle name="type" initialValue={type} />
      {type === 'edit' ? (
        <>
          <Form.Item hidden noStyle name="id" />
          <Form.Item hidden noStyle name="companyName" />
          <Form.Item hidden noStyle name="companyId" />
        </>
      ) : (
        <>
          <Form.Item hidden noStyle name="companyId" />
          <Form.Item labelCol={{ span: 12 }} labelAlign="left" label={t('projects.company')} name="companyId">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => filterOption(input, option as any)}
              options={companies?.map(comp => ({ key: comp.id, label: comp.name, value: comp.id }))}
              style={{ width: '77%' }}
              placeholder={t('projects.company')}
            />
          </Form.Item>
        </>
      )}
      <Form.Item initialValue={0} labelCol={{ span: 12 }} labelAlign="left" label={t('projects.generalInformation.title')} name="general">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={RightsEnum.NONE}>{t('projects.authorization.none')}</Radio>
          <Radio value={RightsEnum.READ}>{t('projects.authorization.read')}</Radio>
          <Radio value={RightsEnum.WRITE}>{t('projects.authorization.write')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        initialValue={0}
        labelCol={{ span: 12 }}
        labelAlign="left"
        label={t('projects.geometryAndConstruction.title')}
        name="geometryAndConstruction">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={RightsEnum.NONE}>{t('projects.authorization.none')}</Radio>
          <Radio value={RightsEnum.READ}>{t('projects.authorization.read')}</Radio>
          <Radio value={RightsEnum.WRITE}>{t('projects.authorization.write')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item initialValue={0} labelCol={{ span: 12 }} labelAlign="left" label={t('projects.siom.title')} name="siom">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={RightsEnum.NONE}>{t('projects.authorization.none')}</Radio>
          <Radio value={RightsEnum.READ}>{t('projects.authorization.read')}</Radio>
          <Radio value={RightsEnum.WRITE}>{t('projects.authorization.write')}</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};
