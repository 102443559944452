// ExportModal.tsx
import { getDataExchangeExportById } from '@/modules/project/model';
import { TransferState } from '@/shared/@enums';
import { Alert, Divider, Form, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ExportDataType } from '../models/data-exchange-model';

export const ExportModal: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [exportableFiles, setExportableFiles] = useState<ExportDataType[]>();
  const [exportState, setExportState] = useState<TransferState>(TransferState.NONE);

  useEffect(() => {
    const abortController = new AbortController();
    getDataExchangeExportById(projectId || '', abortController.signal)
      .then(res => {
        setExportableFiles([{ file: res.file, name: res.name } as ExportDataType]);
      })
      .catch(e => {
        if (!abortController.signal.aborted) console.error(e);
      });
    return () => {
      abortController.abort();
    };
  }, [projectId]);

  return (
    <>
      <Divider />
      <Form.Item name="file">
        <Select
          loading={!exportableFiles?.length}
          disabled={exportState !== TransferState.NONE || !exportableFiles?.length}
          placeholder={t('actions.selectFile')}
          style={{ width: '100%' }}
          onChange={value => {
            const file = exportableFiles?.find(file => file.name === value)?.file;
            const a = document.createElement('a'); //Create <a>
            a.href = `data:application/octet-stream;base64,${file}`; //Image Base64 Goes here
            a.download = value; //File name Here
            a.click(); //Downloaded file
            setExportState(TransferState.SUCCESS);
          }}>
          {exportableFiles?.map(file => (
            <Select.Option key={file.name} value={file.name}>
              {file.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {exportState === TransferState.SUCCESS && (
        <Alert
          message={t('projects.dataExchange.export.success.title')}
          description={t('projects.dataExchange.export.success.subTitle')}
          type="success"
        />
      )}
      {exportState === TransferState.ERROR && (
        <Alert
          message={t('projects.dataExchange.export.error.title')}
          description={t('projects.data.dataExchange.error.subtitle')}
          type="error"
        />
      )}
      <Divider />
    </>
  );
};

export default ExportModal;
